.about{
    width: 100%;
    background: #F67B6F;
}

.container-about{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.about-right{
    width: calc(50%);
    padding-left: 5rem;
}

.title-about{
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.about-left span{
    font-size: 1.5rem;
    letter-spacing: 4px;
    font-weight: 500;
}

.about-right p{
    letter-spacing: 3px;
    font-size: 1.5rem;
}


.numbers{
    display: flex;
}

.number-1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.number-2{
    font-size: 5rem;
    font-weight: 700;
}

.number-1 span{
    text-align: center;
}



@media (max-width:768px){
    .container-about{
        flex-direction: column;

    }

    .about-right {
        width: 100%;
        padding-left: 0;
        padding-top: 3rem;
    }
    }