.depoimento{
    width: 100%;
    position: relative;
}

.right-color{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    top: -600px;
}


.color-rose{
    background-color: #F67B6F ;
    height: 600px ;
    width: calc(50% ) !important;
}

.white-color{
    background-color: white;
    height: 180px;
    width: calc(50%);
}

.blue-color{
    background-color: #D3E1E0;
    height: 420px;
    width: calc(50%);
  
}

.container-depoimento img{
    border-radius: 100%;
    height: 700px;
    width: 700px;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
}


.green-card{
    background-color: #476160;
    gap: 1rem;
    color: white;
    padding: 40px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-box-shadow: 0px 0px 6px -1px #000000; 
    box-shadow: 0px 0px 6px -1px #000000;  
    
}

.carousel{
    margin-top: -13rem;
    padding-bottom: 2rem;
    position: relative;
}

.btn-card2{
    padding: 8px 11px !important;
}

.swiper{
    min-height: 430px !important;
}

.green-card h2{
    font-weight: 600;
    font-size: 2.3rem;
}

.green-card p{
    font-size: 1.2rem;
    letter-spacing: 2px;
    font-weight: 400;
}



@media (max-width:1440px){
    .swiper {
        min-height: 500px !important;
    }
}


@media (max-width:768px){
.swiper {
    min-height: 400px !important;
}
}


@media (max-width:425px){
.conteudo-depo{
    height: 1000px;
}

.container-depoimento img {
    height: 500px;
    width: 400px;
}

.swiper {
    min-height: 525px !important;
}

}

@media (max-width: 375px){
.container-depoimento img {
    height: 400px;
    width: 350px;
}

.conteudo-depo {
    height: 900px;
}

.swiper {
    min-height: 590px !important;
}

}