.grid{
 
    height: calc(100%);
    padding-top: 6rem;
    padding-bottom: 6rem;
    background-image: url('../../images/home.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}
.grid::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(0, 0, 0, 0.4);
}

.container-grid{
    position: relative;
}


.container-cards{
    justify-content: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-bottom: 1rem;
    padding: 2rem 0;
    border-radius: 30px;
}

.container-cards svg{
    font-size: 50px;
    color: #476160;
}

.container-cards p{
    color: #476160;
    letter-spacing: 4px;
    font-size: 1.5rem;
    font-weight: 600;
}

.container-svg{
    background-color: #D3E1E0;
    padding: 2rem;
    border-radius: 100%;
}



